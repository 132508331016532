const env = {
  production: {
    API_URL: "http://java-api-gateway-1712096883.us-east-1.elb.amazonaws.com/",
    API_URL_CLIENT:
      "http://java-api-gateway-1712096883.us-east-1.elb.amazonaws.com/ilifeService/",
    API_URL_UNDERWRITING:
      "http://java-api-gateway-1712096883.us-east-1.elb.amazonaws.com/underwritingService/",
    API_URL_CHATSERVICE:
      "http://java-api-gateway-1712096883.us-east-1.elb.amazonaws.com/chatService/",
    API_URL_PAYMENTSERVICE:
      "http://java-api-gateway-1712096883.us-east-1.elb.amazonaws.com/paymentService/",
    API_URL_HUMAN_API:
      "http://java-api-gateway-1712096883.us-east-1.elb.amazonaws.com/humanapiService/",
    API_URL_MUNICH:
      "http://java-api-gateway-1712096883.us-east-1.elb.amazonaws.com/munichreService/",
    AGENT_PORTAL_URL: "http://54.162.2.113:3001/",
    CONSUMER_PORTAL_URL: "https://client.ilife.tech/",
    LANDING_PAGE_URL: 'https://landing.ilife.tech',
    CONSUMER_DOMAIN_NAME: "client",
    PORT: "0000",
    ALGORITHM: process.env.REACT_APP_ALGORITHM,
    ALGORITHM_KEY: process.env.REACT_APP_ALGORITHM_KEY,
    ALGORITHM_IV: process.env.REACT_APP_ALGORITHM_IV,
    ZENDESK_KEY: process.env.REACT_APP_ZENDESK_KEY,
    GEO_CODES_KEY: process.env.REACT_APP_GEOCODES_KEY,
  },
  staging: {
    API_URL: "http://java-api-gateway-1712096883.us-east-1.elb.amazonaws.com/",
    API_URL_CLIENT:
      "http://java-api-gateway-1712096883.us-east-1.elb.amazonaws.com/ilifeService/",
    API_URL_UNDERWRITING:
      "http://java-api-gateway-1712096883.us-east-1.elb.amazonaws.com/underwritingService/",
    API_URL_CHATSERVICE:
      "http://java-api-gateway-1712096883.us-east-1.elb.amazonaws.com/chatService/",
    API_URL_PAYMENTSERVICE:
      "http://java-api-gateway-1712096883.us-east-1.elb.amazonaws.com/paymentService/",
    API_URL_HUMAN_API: "http://java-api-gateway-1712096883.us-east-1.elb.amazonaws.com/humanapiService/",
    API_URL_MUNICH:
      "http://java-api-gateway-1712096883.us-east-1.elb.amazonaws.com/munichreService/",
    AGENT_PORTAL_URL: "http://54.162.2.113:3001/",
    CONSUMER_PORTAL_URL: "https://client.ilife.tech/",
    LANDING_PAGE_URL: 'https://landing.ilifestaging.tech',
    CONSUMER_DOMAIN_NAME: "client",
   CLIENT_DOMAIN_NAME: ".ilifestaging.tech",
    PORT: 3000,
    ALGORITHM: process.env.REACT_APP_ALGORITHM,
    ALGORITHM_KEY: process.env.REACT_APP_ALGORITHM_KEY,
    ALGORITHM_IV: process.env.REACT_APP_ALGORITHM_IV,
    ZENDESK_KEY: process.env.REACT_APP_ZENDESK_KEY,
    GEO_CODES_KEY: process.env.REACT_APP_GEOCODES_KEY,
  },
  development: {
   API_URL: "https://ilife-api.devpress.net/",
    API_URL_CLIENT: "https://ilife-api.devpress.net/ilifeService/",
    API_URL_UNDERWRITING: "https://ilife-api.devpress.net/underwritingService/",
    API_URL_CHATSERVICE: "https://ilife-chat-api.devpress.net/",
    API_URL_PAYMENTSERVICE: "https://ilife-api.devpress.net/paymentService/",
    API_URL_HUMAN_API: "https://ilife-api.devpress.net/humanapiService/",
    API_URL_MUNICH: "https://ilife-api.devpress.net/munichreService/",
    AGENT_PORTAL_URL: "https://ilife-agent.devpress.net/",
    CONSUMER_PORTAL_URL: "https://ilife-client.devpress.net/",
    LANDING_PAGE_URL: 'https://ilife-tech.devpress.net',
    CONSUMER_DOMAIN_NAME: "ilife-client",
   CLIENT_DOMAIN_NAME: ".devpress.net",
    PORT: 3000,
    ALGORITHM: process.env.REACT_APP_ALGORITHM,
    ALGORITHM_KEY: process.env.REACT_APP_ALGORITHM_KEY,
    ALGORITHM_IV: process.env.REACT_APP_ALGORITHM_IV,
    ZENDESK_KEY: process.env.REACT_APP_ZENDESK_KEY,
    GEO_CODES_KEY: process.env.REACT_APP_GEOCODES_KEY,
  },
  local: {
    API_URL: "http://10.2.1.202:9000/",
    API_URL_CLIENT: "http://10.2.1.202:9000/ilifeService/",
    API_URL_UNDERWRITING: "http://10.2.1.202:9000/underwritingService/",
    API_URL_PAYMENTSERVICE: "http://10.2.1.202:9000/paymentService/",
    API_URL_CHATSERVICE: "http://10.2.1.202:9007/",
    API_URL_HUMAN_API: "http://10.2.1.202:9000/humanapiService/",
    API_URL_MUNICH: "http://10.2.1.202:9000/munichreService/",
    AGENT_PORTAL_URL: "http://54.162.2.113:3001/",
    CONSUMER_PORTAL_URL: "https://ilife-client.devpress.net/",
    LANDING_PAGE_URL: 'https://ilife-tech.devpress.net',
    CONSUMER_DOMAIN_NAME: "ilife-client",
   CLIENT_DOMAIN_NAME: ".devpress.net",
    PORT: 3000,
    ALGORITHM: process.env.REACT_APP_ALGORITHM,
    ALGORITHM_KEY: process.env.REACT_APP_ALGORITHM_KEY,
    ALGORITHM_IV: process.env.REACT_APP_ALGORITHM_IV,
    ZENDESK_KEY: process.env.REACT_APP_ZENDESK_KEY,
    GEO_CODES_KEY: process.env.REACT_APP_GEOCODES_KEY,
  }
};

module.exports = env[process.env.REACT_APP_NODE_ENV];
